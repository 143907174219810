import React from "react";

const Facebook = ({ facebookPage, bandName }) => {
  return (
    <a href={`https://www.facebook.com/${facebookPage}`} className="facebook-link">
      Follow <b>{bandName}</b> on Facebook!
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
        alt="Facebook logo"
        className="facebook-logo"
      />
    </a>
  );
};

export default Facebook;
