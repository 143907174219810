import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const siteName = process.env.REACT_APP_SITE || '';

// Set dynamic favicon paths and manifest
const faviconPath = `/favicons/${siteName}/`;  // The path to your favicons

// Update the favicon links in the <head>
document.getElementById('apple-touch-icon').setAttribute('href', `${process.env.PUBLIC_URL}/favicons/${siteName}/apple-touch-icon.png`);
document.getElementById('favicon-96x96').setAttribute('href', `${process.env.PUBLIC_URL}/favicons/${siteName}/favicon-96x96.png`);
document.getElementById('favicon-ico').setAttribute('href', `${process.env.PUBLIC_URL}/favicons/${siteName}/favicon.ico`);
document.getElementById('favicon-svg').setAttribute('href', `${process.env.PUBLIC_URL}/favicons/${siteName}/favicon.svg`);

document.getElementById('site-webmanifest').setAttribute('href', `${process.env.PUBLIC_URL}/favicons/${siteName}/site.webmanifest`);

// Update the manifest file for web app icons
document.getElementById('mstile-144x144').setAttribute('content', `${process.env.PUBLIC_URL}/favicons/${siteName}/web-app-manifest-192x192.png`);
document.querySelector('meta[name="msapplication-config"]').setAttribute('content', `${process.env.PUBLIC_URL}/favicons/${siteName}/browserconfig.xml`);


const siteTitle = process.env.REACT_APP_BAND_NAME || 'Loading....';
document.title = siteTitle;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
